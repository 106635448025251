import * as React from "react"
import Layout from "../../components/layout"

import ColumnWithIcon from "../../modules/columnWithIcon";
import Image from "../../modules/imageComponent";
import Paginator from "../../modules/paginator";

import anim1 from '../../images/animations/icon_1/animation'
import anim2 from '../../images/animations/icon_2/animation'
import anim3 from '../../images/animations/icon_3/animation'
import anim4 from '../../images/animations/icon_4/animation'

import mainAnim from '../../images/animations/main_icon_1/animation'

import Lottie from "react-lottie-player";

const TransactPage = () => {
    return (
        <Layout className={'technology'} title={'Transact'}>
            <section>
                <div className={'container'}>

                    <div className={'row pb-0 mb-0 align-items-center'}>
                        <div className={'col col-12 col-md-4 col-lg-3 col-md-3'}>
                        </div>
                        <div className={'col col-12 col-md-8 col-lg-9'}>
                            <Paginator current={1} prev={4} next={2}/>
                        </div>
                    </div>
                    <div className={'row pb-lg-5 align-items-center'}>
                        <div className={'col col-12 col-md-4 col-lg-3 py-5 py-md-0 pe-md-5 hero-image transact'}>
                            <Lottie animationData={mainAnim} play loop={false}/>
                        </div>
                        <div className={'col col-12 col-md-5 align-top'}>
                            <h1>Transact</h1>
                            <div className={'subtitle'}>
                                Natively cross-chain, with shielded transactions in any cryptoasset.
                            </div>
                        </div>
                    </div>

                    <div className={'row pt-4'}>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim1} icon={'technology/icon6.svg'} title={'Transact Privately'} text={'Penumbra is a fully shielded blockchain, keeping user’s financial data safe and secure. All transactions are private by default and cannot be traced or decrypted without a user’s permission.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim2} icon={'technology/icon7.svg'} title={'Natively Cross-Chain'} text={'Penumbra works with Inter Blockchain Communication (IBC) to shield any kind of cryptoasset originating from any IBC-compatible chain. As a result, Penumbra acts as a shielded pool for the entire Cosmos ecosystem and beyond.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim3} icon={'technology/icon8.svg'} title={'Transparency on Demand'} text={'Every Penumbra address supports viewing capability, allowing users to opt in to disclose their activity to third parties they authorize for compliance or other reasons.'}/>
                        </div>
                        <div className={'col col-12 col-md-6 col-lg-3 py-5 py-md-5 px-lg-0'}>
                            <ColumnWithIcon animation={anim4} icon={'technology/icon9.svg'} title={'Fast, Low-fee Transactions'} text={'Penumbra transactions feature low fees asd fast finality, thanks to its status as a sovereign layer-1 blockchain using proof-of-stake consensus.'}/>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default TransactPage
